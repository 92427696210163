import React from "react";
import { useContext, useState, useEffect } from "react";
import ReactInputMask from "react-input-mask";
import ResizeTextarea from "react-textarea-autosize";
import { Grid, GridItem, Text, Input, Button, Select, Stack, Box, Heading, Flex, Divider, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, ModalFooter, Tabs, TabList, Tab, TabPanels, TabPanel, Container, SimpleGrid, Table, Tbody, Tr, Td, TableContainer, Thead, Th, Spacer, Center, VStack, Image, AspectRatio, ButtonGroup, Textarea, Checkbox } from '@chakra-ui/react';
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { SaveIcon } from "../../icons/saveicon";

import NavSuperior from "../../components/NavBar/navsuperior";
import DadosRemessa from "../../components/Remessa/dadosremessa";

import { AuthContext } from "../../contexts/auth";
import { ReurbContext } from "../../contexts/reurb";

import { api } from "../../../services/api";
import { urlImages } from "../../../services/api";

const ConfiguracoesPage = () => {

  //Variáveis do Contexto
  const { logout } = useContext(AuthContext);
  const { rem_id } = useContext(ReurbContext);

  //Variáveis Chakra UI
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen: isOutorgadoOpen, onOpen: onOutorgadoOpen, onClose: onOutorgadoClose } = useDisclosure();

  //Variáveis Remessa    
  const [rem_municipio, setRem_municipio] = useState("");
  const [rem_uf, setRem_uf] = useState("");
  const [rem_sigla, setRem_sigla] = useState("");
  const [rem_cep, setRem_cep] = useState("");
  const [rem_remessa, setRem_remessa] = useState("");
  const [rem_titulo, setRem_titulo] = useState("");
  const [rem_descricao, setRem_descricao] = useState("");

  //Contratada
  const [emp_tipo, setEmp_tipo] = useState("");
  const [emp_nome, setEmp_nome] = useState("");
  const [emp_empresa, setEmp_empresa] = useState("");
  const [emp_cnpj, setEmp_cnpj] = useState("");
  const [emp_cpf, setEmp_cpf] = useState("");
  const [emp_nacionalidade, setEmp_nacionalidade] = useState("");
  const [emp_estadocivil, setEmp_estadocivil] = useState("");
  const [emp_profissao, setEmp_profissao] = useState("");
  const [emp_registro, setEmp_registro] = useState("");
  const [emp_logradouro, setEmp_logradouro] = useState("");
  const [emp_numero, setEmp_numero] = useState("");
  const [emp_complemento, setEmp_complemento] = useState("");
  const [emp_bairro, setEmp_bairro] = useState("");
  const [emp_municipio, setEmp_municipio] = useState("");
  const [emp_uf, setEmp_uf] = useState("");
  const [emp_cep, setEmp_cep] = useState("");
  const [emp_email, setEmp_email] = useState("");

  //Contrato
  const [con_tipo, setCon_tipo] = useState("");
  const [con_adicional, setCon_adicional] = useState(0);
  const [con_valor_global, setCon_valor_global] = useState("");
  const [con_valor_global_s, setCon_valor_global_s] = useState("");
  const [con_valor_global_e, setCon_valor_global_e] = useState("");
  const [con_valor_app, setCon_valor_app] = useState("");
  const [con_valor_risco, setCon_valor_risco] = useState("");
  const [con_valor_apperisco, setCon_valor_apperisco] = useState("");
  const [con_qtd_parcelas, setCon_qtd_parcelas] = useState("");
  const [con_qtd_parcelas_s, setCon_qtd_parcelas_s] = useState("");
  const [con_qtd_parcelas_e, setCon_qtd_parcelas_e] = useState("");
  const [con_valor_parcela, setCon_valor_parcela] = useState("");
  const [con_valor_parcela_s, setCon_valor_parcela_s] = useState("");
  const [con_valor_parcela_e, setCon_valor_parcela_e] = useState("");
  const [con_dia_vencimento, setCon_dia_vencimento] = useState("");
  const [con_multa_alter_porcent, setCon_multa_alter_porcent] = useState("");
  const [con_multa_cancel_porcent, setCon_multa_cancel_porcent] = useState("");
  const [con_inicio_pagamento, setCon_inicio_pagamento] = useState("");
  const [con_comarca, setCon_comarca] = useState("");

  //Outorgado
  const [lista_outorgados, setLista_outorgados] = useState([{ out_id: 0, out_nome: '', out_profissao: '' }]);
  const [out_selecionado, setOut_selecionado] = useState(0);
  const [out_qualificacao, setOut_qualificacao] = useState("");
  const [out_info_faltante, setOut_info_faltante] = useState("");

  const [out_id, setOut_id] = useState(0);
  const [out_nome, setOut_nome] = useState("");
  const [out_nacionalidade, setOut_nacionalidade] = useState("");
  const [out_cpf, setOut_cpf] = useState("");
  const [out_estado_civil, setOut_estado_civil] = useState("");
  const [out_profissao, setOut_profissao] = useState("");
  const [out_registro_profissional, setOut_registro_profissional] = useState("");
  const [out_logradouro, setOut_logradouro] = useState("");
  const [out_numero, setOut_numero] = useState("");
  const [out_bairro, setOut_bairro] = useState("");
  const [out_municipio, setOut_municipio] = useState("");
  const [out_cep, setOut_cep] = useState("");
  const [out_uf, setOut_uf] = useState("");

  //Timbrado
  const [lista_timbrados, setLista_timbrados] = useState([{ tim_id: 0, tim_nome: '' }]);
  const [tim_selecionado, setTim_selecionado] = useState(0);
  const [tim_descricao, setTim_descricao] = useState("");
  const [tim_imagem, setTim_imagem] = useState("");

  //Ler dados Configurar Remessa
  useEffect(() => {
    api.post('/configuracoes/LerConfigurarRemessa.php', JSON.stringify({ rem_id }))
      .then(function (rp) {

        setRem_municipio(rp.data.rem_municipio);
        setRem_uf(rp.data.rem_uf);
        setRem_sigla(rp.data.rem_sigla);
        setRem_cep(rp.data.rem_cep);
        setRem_remessa(rp.data.rem_remessa);
        setRem_titulo(rp.data.rem_titulo);
        setRem_descricao(rp.data.rem_descricao);

        setEmp_tipo(rp.data.emp_tipo);
        setEmp_nome(rp.data.emp_nome);
        setEmp_empresa(rp.data.emp_empresa);
        setEmp_cnpj(rp.data.emp_cnpj);
        setEmp_cpf(rp.data.emp_cpf);
        setEmp_nacionalidade(rp.data.emp_nacionalidade);
        setEmp_estadocivil(rp.data.emp_estadocivil);
        setEmp_profissao(rp.data.emp_profissao);
        setEmp_registro(rp.data.emp_registro);
        setEmp_logradouro(rp.data.emp_logradouro);
        setEmp_numero(rp.data.emp_numero);
        setEmp_complemento(rp.data.emp_complemento);
        setEmp_bairro(rp.data.emp_bairro);
        setEmp_municipio(rp.data.emp_municipio);
        setEmp_uf(rp.data.emp_uf);
        setEmp_cep(rp.data.emp_cep);
        setEmp_email(rp.data.emp_email);

        setCon_tipo(rp.data.con_tipo);
        setCon_adicional(rp.data.con_adicional);
        setCon_valor_global(rp.data.con_valor_global);
        setCon_valor_global_s(rp.data.con_valor_global_s);
        setCon_valor_global_e(rp.data.con_valor_global_e);
        setCon_valor_app(rp.data.con_valor_app);
        setCon_valor_risco(rp.data.con_valor_risco);
        setCon_valor_apperisco(rp.data.con_valor_apperisco);
        setCon_qtd_parcelas(rp.data.con_qtd_parcelas);
        setCon_qtd_parcelas_s(rp.data.con_qtd_parcelas_s);
        setCon_qtd_parcelas_e(rp.data.con_qtd_parcelas_e);
        setCon_valor_parcela(rp.data.con_valor_parcela);
        setCon_valor_parcela_s(rp.data.con_valor_parcela_s);
        setCon_valor_parcela_e(rp.data.con_valor_parcela_e);
        setCon_dia_vencimento(rp.data.con_dia_vencimento);
        setCon_multa_alter_porcent(rp.data.con_multa_alter_porcent);
        setCon_multa_cancel_porcent(rp.data.con_multa_cancel_porcent);
        setCon_inicio_pagamento(rp.data.con_inicio_pagamento);
        setCon_comarca(rp.data.con_comarca);

        setLista_outorgados(rp.data.lista_outorgados);
        setOut_selecionado(rp.data.out_selecionado);
        setOut_id(rp.data.out_selecionado);

        setOut_qualificacao(rp.data.out_qualificacao);
        setOut_info_faltante(rp.data.out_info_faltante);

        setLista_timbrados(rp.data.lista_timbrados);
        setTim_selecionado(rp.data.tim_selecionado);

        setTim_descricao(rp.data.tim_descricao);
        setTim_imagem(rp.data.tim_imagem);

      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        }
      });
  }, []);

  //Recarregar dados dos outorgados
  function recarregarOutorgados() {
    api.post('/configuracoes/LerConfigurarRemessa.php', JSON.stringify({ rem_id }))
      .then(function (rp) {

        setLista_outorgados(rp.data.lista_outorgados);
        setOut_selecionado(rp.data.out_selecionado);
        setOut_id(rp.data.out_selecionado);

        setOut_qualificacao(rp.data.out_qualificacao);
        setOut_info_faltante(rp.data.out_info_faltante);

      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        }
      });
  }

  //Ler dados Outorgado
  function lerOutorgado(id) {
    setOut_id(id);
    //Dados do Outorgado    
    api.post('/configuracoes/LerOutorgado.php', JSON.stringify({ out_id: id }))
      .then(function (rp) {

        setOut_nome(rp.data.out_nome);
        setOut_nacionalidade(rp.data.out_nacionalidade);
        setOut_cpf(rp.data.out_cpf);
        setOut_estado_civil(rp.data.out_estado_civil);
        setOut_profissao(rp.data.out_profissao);
        setOut_registro_profissional(rp.data.out_registro_profissional);
        setOut_logradouro(rp.data.out_logradouro);
        setOut_numero(rp.data.out_numero);
        setOut_bairro(rp.data.out_bairro);
        setOut_municipio(rp.data.out_municipio);
        setOut_cep(rp.data.out_cep);
        setOut_uf(rp.data.out_uf);

      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        }
      });
  }

  //Salvar geral
  async function salvarGeral() {
    const data = {
      //Remessa
      rem_id,
      rem_sigla,
      rem_cep,
      rem_titulo,
      rem_descricao
    }
    await api.post('/configuracoes/SalvarGeral.php', JSON.stringify(data))
      .then(function (rp) {

        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
        })
        )

      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          return (toast({
            position: 'bottom-left', render: () => (<Box color='white' p={3} bg='red'>Erro: não foi possível registrar os dados!</Box>),
          })
          )
        }
      });
  }

  //Ler dados empresa
  function lerDadosEmpresa(sigla_empresa) {
    const data = {
      rem_id,
      sigla_empresa
    }
    api.post('/configuracoes/LerDadosEmpresa.php', JSON.stringify(data))
      .then(function (rp) {

        setEmp_nome(rp.data.emp_nome);
        setEmp_empresa(rp.data.emp_empresa);
        setEmp_cnpj(rp.data.emp_cnpj);
        setEmp_cpf(rp.data.emp_cpf);
        setEmp_nacionalidade(rp.data.emp_nacionalidade);
        setEmp_estadocivil(rp.data.emp_estadocivil);
        setEmp_profissao(rp.data.emp_profissao);
        setEmp_registro(rp.data.emp_registro);
        setEmp_logradouro(rp.data.emp_logradouro);
        setEmp_numero(rp.data.emp_numero);
        setEmp_complemento(rp.data.emp_complemento);
        setEmp_bairro(rp.data.emp_bairro);
        setEmp_municipio(rp.data.emp_municipio);
        setEmp_uf(rp.data.emp_uf);
        setEmp_cep(rp.data.emp_cep);
        setEmp_email(rp.data.emp_email);

      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
        }
      });
  }

  //Salvar contratada
  async function salvarContratada() {
    const data = {

      //Remessa
      rem_id,
      emp_tipo,
      emp_nome,
      emp_empresa,
      emp_cnpj,
      emp_cpf,
      emp_nacionalidade,
      emp_estadocivil,
      emp_profissao,
      emp_registro,
      emp_logradouro,
      emp_numero,
      emp_complemento,
      emp_bairro,
      emp_municipio,
      emp_uf,
      emp_cep,
      emp_email

    }
    await api.post('/configuracoes/SalvarContratada.php', JSON.stringify(data))
      .then(function (rp) {
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
        })
        );
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
          return (toast({
            position: 'bottom-left', render: () => (<Box color='white' p={3} bg='red'>Erro: não foi possível registrar os dados!</Box>),
          })
          )
        }
      });
  }

  //Salvar contrato
  async function salvarContrato() {
    const data = {

      //Remessa
      rem_id,
      con_tipo,
      con_adicional,
      con_valor_global,
      con_valor_global_s,
      con_valor_global_e,
      con_valor_app,
      con_valor_risco,
      con_valor_apperisco,
      con_qtd_parcelas,
      con_qtd_parcelas_s,
      con_qtd_parcelas_e,
      con_valor_parcela,
      con_valor_parcela_s,
      con_valor_parcela_e,
      con_dia_vencimento,
      con_multa_alter_porcent,
      con_multa_cancel_porcent,
      con_inicio_pagamento,
      con_comarca

    }
    await api.post('/configuracoes/SalvarContrato.php', JSON.stringify(data))
      .then(function (rp) {
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
        })
        );
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
          return (toast({
            position: 'bottom-left', render: () => (<Box color='white' p={3} bg='red'>Erro: não foi possível registrar os dados!</Box>),
          })
          );
        }
      });
  }

  //Salvar outorgado selecionado
  async function handleOutorgadoSelecionado(id) {

    setOut_selecionado(id);
    setOut_id(id);

    const data = {
      rem_id,
      out_selecionado: id
    }
    await api.post('/configuracoes/DefinirOutorgado.php', JSON.stringify(data))
      .then(function (rp) {
        setOut_qualificacao(rp.data.out_qualificacao);
        setOut_info_faltante(rp.data.out_info_faltante);
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
        })
        );
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
          return (toast({
            position: 'bottom-left', render: () => (<Box color='white' p={3} bg='red'>Erro: não foi possível registrar os dados!</Box>),
          })
          );
        }
      });
  }

  //Salvar Outorgado
  async function salvarOutorgado() {
    const data = {
      //Outorgado
      out_id,
      out_nome,
      out_nacionalidade,
      out_cpf,
      out_estado_civil,
      out_profissao,
      out_registro_profissional,
      out_logradouro,
      out_numero,
      out_bairro,
      out_municipio,
      out_cep,
      out_uf
    }
    await api.post('/configuracoes/SalvarOutorgado.php', JSON.stringify(data))
      .then(function (rp) {
        onOutorgadoClose();
        limparCamposOutorgado();
        recarregarOutorgados();
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg='teal'>Dados Registrados!</Box>),
        })
        );
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
          return (toast({
            position: 'bottom-left', render: () => (<Box color='white' p={3} bg='red'>Erro: não foi possível registrar os dados!</Box>),
          })
          );
        }
      });
  }

  //Excluir Outorgado
  async function excluirOutorgado() {
    const data = {
      //Outorgado
      out_id
    }
    await api.post('/configuracoes/ExcluirOutorgado.php', JSON.stringify(data))
      .then(function (rp) {
        onOutorgadoClose();
        limparCamposOutorgado();
        recarregarOutorgados();
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg='teal'>Outorgado excluído!</Box>),
        })
        );
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
          return (toast({
            position: 'bottom-left', render: () => (<Box color='white' p={3} bg='red'>Erro: não foi possível excluir o registro!</Box>),
          })
          );
        }
      });
  }

  //Salvar timbrado selecionado
  async function handleTimbradoSelecionado(id) {
    setTim_selecionado(id);
    const data = {
      rem_id,
      tim_selecionado: id
    }
    await api.post('/configuracoes/DefinirTimbrado.php', JSON.stringify(data))
      .then(function (rp) {
        setTim_descricao(rp.data.tim_descricao);
        setTim_imagem(rp.data.tim_imagem);
        return (toast({
          position: 'bottom-left', render: () => (<Box color='white' p={3} bg={rp.data.sucesso === true ? 'teal' : 'red'}>{rp.data.msg}</Box>),
        })
        );
      })
      .catch(function (error) {
        if (error.response.data === "EXPIRED") {
          logout();
        } else {
          console.log(error);
          return (toast({
            position: 'bottom-left', render: () => (<Box color='white' p={3} bg='red'>Erro: não foi possível registrar os dados!</Box>),
          })
          );
        }
      });
  }

  //Limpar Campos
  function limparCamposOutorgado() {

    setOut_id(0);

    setOut_nome('');
    setOut_nacionalidade('');
    setOut_cpf('');
    setOut_estado_civil('');
    setOut_profissao('');
    setOut_registro_profissional('');
    setOut_logradouro('');
    setOut_numero('');
    setOut_bairro('');
    setOut_municipio('');
    setOut_cep('');
    setOut_uf('');

  }

  //Calcular valor da parcela do contrato
  function valorParcelaContrato(valor_global, qtd_parcelas) {
    if (valor_global !== null && qtd_parcelas !== null) {
      valor_global = valor_global.replace(",", ".");
      qtd_parcelas = qtd_parcelas.replace(",", ".");
      if (parseFloat(valor_global) > 0 && parseFloat(qtd_parcelas) > 0) {
        const valor = String((parseFloat(valor_global) / parseFloat(qtd_parcelas)).toFixed(2));
        setCon_valor_parcela(valor.replace(".", ","));

      } else {
        setCon_valor_parcela("");
      }
    }

  }

  //Calcular valor da parcela do contrato S
  function valorParcelaContratoS(valor_global, qtd_parcelas) {
    if (valor_global !== null && qtd_parcelas !== null) {
      valor_global = valor_global.replace(",", ".");
      qtd_parcelas = qtd_parcelas.replace(",", ".");
      if (parseFloat(valor_global) > 0 && parseFloat(qtd_parcelas) > 0) {
        const valor = String((parseFloat(valor_global) / parseFloat(qtd_parcelas)).toFixed(2));
        setCon_valor_parcela_s(valor.replace(".", ","));

      } else {
        setCon_valor_parcela_s("");
      }
    }

  }

  //Calcular valor da parcela do contrato E
  function valorParcelaContratoE(valor_global, qtd_parcelas) {
    if (valor_global !== null && qtd_parcelas !== null) {
      valor_global = valor_global.replace(",", ".");
      qtd_parcelas = qtd_parcelas.replace(",", ".");
      if (parseFloat(valor_global) > 0 && parseFloat(qtd_parcelas) > 0) {
        const valor = String((parseFloat(valor_global) / parseFloat(qtd_parcelas)).toFixed(2));
        setCon_valor_parcela_e(valor.replace(".", ","));

      } else {
        setCon_valor_parcela_e("");
      }
    }

  }

  //Ir para remessa
  function goToRemessa() {
    navigate('/remessa');
  }

  return (
    <div id="configuracoes">
      <NavSuperior />
      <DadosRemessa titulo="Configurar Remessa" />
      <Container maxW='95%' bg='white'>
        <Flex direction='row' w={'100%'} h={'60px'} bg={'gray.200'}>
          <Center w={'100px'} h={'100%'} color={'gray.600'} cursor='pointer' _hover={{ color: 'teal' }} onClick={e => goToRemessa()}>
            <ChevronLeftIcon boxSize={12} />
          </Center>
          <Center>
            <Heading size={'md'} color={'gray.700'} ml={3}>{rem_municipio + ' (' + rem_uf + ') - Remessa ' + rem_remessa}</Heading>
          </Center>
          <Spacer />
        </Flex>
        <Tabs isFitted>
          <TabList color={'gray.600'} backgroundColor={'gray.200'} h={'40px'}>
            <Tab className="geral" _selected={{ bg: 'gray.300' }}><Heading size='sm'>GERAL</Heading></Tab>
            <Tab className="outorgado" _selected={{ bg: 'gray.300' }}><Heading size='sm'>OUTORGADO</Heading></Tab>
            <Tab className="contratada" _selected={{ bg: 'gray.300' }}><Heading size='sm'>CONTRATADA</Heading></Tab>
            <Tab className="contrato" _selected={{ bg: 'gray.300' }}><Heading size='sm'>CONTRATO</Heading></Tab>
            <Tab className="timbrado" _selected={{ bg: 'gray.300' }}><Heading size='sm'>TIMBRADO</Heading></Tab>
          </TabList>
          <Box overflowX="auto"
            whiteSpace="nowrap"
            sx={{
              '&::-webkit-scrollbar': {
                width: '16px',
                borderRadius: '8px',
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: `rgba(0, 0, 0, 0.05)`,
              },
            }}
            w='100%'
            height={(window.innerHeight - 300)}>
            <TabPanels mt={5}>
              <TabPanel className="geral">
                <Heading as='h4' size='md' textAlign={'center'}>
                  Definições Gerais
                </Heading>
                <Box pr={'5%'} pl={'5%'} mt={8}>
                  <Grid
                    h='50px'
                    templateRows='repeat(1, 1fr)'
                    templateColumns='repeat(12, 1fr)'
                    gap={4}
                  >
                    <GridItem colSpan={5}>
                      <Stack spacing={3} direction='row'><Text>Sigla</Text><Input textAlign={'center'} borderColor={'gray'} w={'150px'} maxLength={3} size='lg' value={rem_sigla} onChange={e => setRem_sigla(e.target.value.toUpperCase())} />
                        <Text>CEP</Text><Input w={'100%'} autoComplete="no" as={ReactInputMask} mask="99.999-999" maskChar={null} borderColor={'gray'} size='lg' value={rem_cep} onChange={e => setRem_cep(e.target.value)} />
                      </Stack>
                    </GridItem>

                    <GridItem colSpan={7}>
                      <Stack spacing={3} direction='row'><Text>Título</Text><Input autoComplete="no" borderColor={'gray'} maxLength={150} size='lg' value={rem_titulo} onChange={e => setRem_titulo(e.target.value)} /></Stack>
                    </GridItem>
                  </Grid>

                  <Textarea borderColor={'gray'} bg={'white'}
                    mt={5}
                    p={3}
                    minH="unset"
                    overflow="hidden"
                    resize="none"
                    minRows={3}
                    as={ResizeTextarea}
                    placeholder="Descrição da remessa..."
                    value={rem_descricao}
                    onChange={e => setRem_descricao(e.target.value)}
                    textAlign={'justify'}
                  />

                  <Divider borderColor={'gray.400'} orientation='horizontal' mt={6} mb={4} />

                  <Flex m='20px'>
                    <Spacer />
                    <Button colorScheme="teal" variant='outline' onClick={e => salvarGeral()}>Salvar Geral<SaveIcon ml={2} /></Button>
                  </Flex>
                </Box>

              </TabPanel>

              <TabPanel className="outorgado">

                <SimpleGrid columns={2} spacing={10}>
                  <Box>
                    <Heading as='h4' size='md' textAlign={'center'}>
                      Lista de Outorgados Cadastrados
                    </Heading>
                    <TableContainer m={5} p={5} border={'1px'} borderColor={'gray.300'}>
                      <Table size='sm'>
                        <Thead>
                          <Tr>
                            <Th textAlign={'center'}>N.</Th>
                            <Th textAlign={'center'}>NOME</Th>
                            <Th textAlign={'center'}>PROFISSÃO</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {lista_outorgados.map((item, i) => {
                            return (
                              <Tr key={i} cursor='pointer' _hover={{ bg: 'gray.200' }} onClick={e => { lerOutorgado(item.out_id); onOutorgadoOpen(); }} >
                                <Td textAlign={'center'}>{i + 1}</Td>
                                <Td>{item.out_nome}</Td>
                                <Td textAlign={'center'}>{item.out_profissao}</Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                      </Table>
                    </TableContainer>
                    <Flex>
                      <Spacer />
                      <Button colorScheme="teal" variant='outline' mr={4} onClick={e => { lerOutorgado(0); limparCamposOutorgado(); onOutorgadoOpen(); }}>Novo Outorgado<PlusSquareIcon ml={2} /></Button>
                    </Flex>
                  </Box>
                  <Box>
                    <Stack direction='column' spacing={3} textAlign={'center'}>
                      <Heading as='h4' size='md' textAlign={'center'} mb={5}>
                        Definir Outorgado da Remessa
                      </Heading>
                      <Center>
                        <Select borderColor={'gray'} size='md' w='500px' value={out_selecionado} onChange={e => { handleOutorgadoSelecionado(parseInt(e.target.value)) }}>
                          <option key={0} value={0}>{''}</option>
                          {lista_outorgados.map((item, i) => {
                            return (<option key={i + 1} value={item.out_id}>{item.out_nome}</option>);
                          })}
                        </Select>
                      </Center>
                      <Text textAlign={'center'} color='gray.500'>
                        Obs. pessoa que irá representar os beneficiários no processo
                      </Text>
                      <Heading as='h4' size='sm' textAlign={'left'} color={'gray'}>
                        QUALIFICAÇÃO
                      </Heading>
                      <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
                      <Text textAlign={'left'}>
                        {out_qualificacao}
                      </Text>
                      <Text textAlign={'left'} color={'red'}>
                        {out_info_faltante.replaceAll(";", "; ")}
                      </Text>

                    </Stack>
                  </Box>

                </SimpleGrid>

                <Modal isOpen={isOutorgadoOpen} onClose={onOutorgadoClose} isCentered size='6xl'>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader textAlign='center'>EDITAR OUTORGADO</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                      <Grid
                        h='100px'
                        templateRows='repeat(5, 1fr)'
                        templateColumns='repeat(12, 1fr)'
                        gap={4}
                      >
                        <GridItem colSpan={5} >
                          <Stack spacing={3} direction='row'><Text>Nome</Text><Input borderColor={'gray'} maxLength={250} placeholder='Nome Completo' size='sm' value={out_nome} onChange={e => setOut_nome(e.target.value)} /></Stack>
                        </GridItem>
                        <GridItem colSpan={3}>
                          <Stack spacing={3} direction='row'><Text>CPF</Text><Input borderColor={'gray'} maxLength={14} size='sm' value={out_cpf} onChange={e => setOut_cpf(e.target.value)} /></Stack>
                        </GridItem>
                        <GridItem colSpan={4}>
                          <Stack spacing={3} direction='row'><Text>Nacionalidade</Text><Input borderColor={'gray'} maxLength={50} size='sm' value={out_nacionalidade} onChange={e => setOut_nacionalidade(e.target.value)} /></Stack>
                        </GridItem>

                        <GridItem colSpan={4}>
                          <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Estado Civil</Text>
                            <Select borderColor={'gray'} size='sm' value={out_estado_civil} onChange={e => setOut_estado_civil(e.target.value)}>
                              <option></option>
                              <option>Casado(a)</option>
                              <option>Solteiro(a)</option>
                              <option>Solteiro(a) com Dissolução</option>
                              <option>Solteiro(a) em União Estável</option>
                              <option>Divorciado(a)</option>
                              <option>Divorciado(a) em União Estável</option>
                              <option>Separado(a)</option>
                              <option>Separado(a) em União Estável</option>
                              <option>Viúvo(a)</option>
                              <option>Viúvo(a) em União Estável</option>
                            </Select>
                          </Stack>
                        </GridItem>
                        <GridItem colSpan={4}>
                          <Stack spacing={3} direction='row'><Text>Profissão</Text><Input borderColor={'gray'} maxLength={50} size='sm' value={out_profissao} onChange={e => setOut_profissao(e.target.value)} /></Stack>
                        </GridItem>
                        <GridItem colSpan={4}>
                          <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Registro Profissional</Text><Input borderColor={'gray'} maxLength={30} placeholder='Ex. OAB 000.00-00' size='sm' value={out_registro_profissional} onChange={e => setOut_registro_profissional(e.target.value)} /></Stack>
                        </GridItem>
                      </Grid>

                      <Grid
                        h={'100px'}
                        templateRows='repeat(4, 1fr)'
                        templateColumns='repeat(12, 1fr)'
                        gap={4}
                      >
                        <GridItem colSpan={5}>
                          <Stack spacing={3} direction='row'><Text>Logradouro</Text><Input borderColor={'gray'} maxLength={250} placeholder='Rua, Avenida, Beco, Rodovia...' size='sm' value={out_logradouro} onChange={e => setOut_logradouro(e.target.value)} /></Stack>
                        </GridItem>
                        <GridItem colSpan={2}>
                          <Stack spacing={3} direction='row'><Text>Número</Text><Input borderColor={'gray'} maxLength={11} size='sm' value={out_numero} onChange={e => setOut_numero(e.target.value)} /></Stack>
                        </GridItem>
                        <GridItem colSpan={5}>
                          <Stack spacing={3} direction='row'><Text>Bairro</Text><Input borderColor={'gray'} maxLength={250} size='sm' value={out_bairro} onChange={e => setOut_bairro(e.target.value)} /></Stack>
                        </GridItem>

                        <GridItem colSpan={7}>
                          <Stack spacing={3} direction='row'><Text>Município</Text><Input borderColor={'gray'} maxLength={250} size='sm' value={out_municipio} onChange={e => setOut_municipio(e.target.value)} /></Stack>
                        </GridItem>
                        <GridItem colSpan={2}>
                          <Stack spacing={3} direction='row'><Text>UF</Text><Input borderColor={'gray'} maxLength={2} size='sm' value={out_uf} onChange={e => setOut_uf(e.target.value)} /></Stack>
                        </GridItem>
                        <GridItem colSpan={3}>
                          <Stack spacing={3} direction='row'><Text>CEP</Text><Input borderColor={'gray'} maxLength={10} size='sm' value={out_cep} onChange={e => setOut_cep(e.target.value)} /></Stack>
                        </GridItem>
                      </Grid>

                    </ModalBody>
                    <ModalFooter>
                      <Button variant='outline' colorScheme='gray' ml='10px' onClick={e => excluirOutorgado()} display={out_id !== 0 ? 'block' : 'none'}>Excluir</Button>
                      <Button colorScheme='gray' ml='10px' onClick={e => salvarOutorgado()}>Salvar Outorgado<SaveIcon ml={2} /></Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>

              </TabPanel>

              <TabPanel className="contratada">
                <VStack>
                  <Container maxW='1300px'>
                    <Flex>
                      <Box w='20%' textAlign={'center'}>
                        <Stack dir="column">
                          <Heading as='h4' size='md' textAlign={'center'}>
                            Tipo de Contratada
                          </Heading>
                          <Button colorScheme={emp_tipo === "Jurídica" ? 'teal' : 'gray'} w={"100%"} rounded={0} h={'70px'} mt={5} onClick={e => setEmp_tipo("Jurídica")}>Pessoa Jurídica</Button>
                          <Button colorScheme={emp_tipo === "Física" ? 'teal' : 'gray'} w={"100%"} rounded={0} h={'70px'} mt={1} onClick={e => setEmp_tipo("Física")}>Pessoa Física</Button>
                        </Stack>
                      </Box>
                      <Box flex='1' pl={10}>
                        <Heading as='h4' size='md' textAlign={'center'}>
                          Dados da Contratada
                        </Heading>
                        <Box p={5}>
                          <Box display={emp_tipo === "Jurídica" ? 'block' : 'none'}>
                            <Grid
                              h='100px'
                              templateRows='repeat(3, 1fr)'
                              templateColumns='repeat(12, 1fr)'
                              gap={4}
                            >
                              <GridItem colSpan={7} >
                                <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Nome</Text><Input borderColor={'gray'} maxLength={250} placeholder='Nome da Empresa' size='sm' value={emp_empresa} onChange={e => setEmp_empresa(e.target.value)} /></Stack>
                              </GridItem>
                              <GridItem colSpan={5}>
                                <Stack spacing={3} direction='row'><Text>CNPJ</Text><Input borderColor={'gray'} maxLength={18} placeholder='__.___.___/____-__' size='sm' value={emp_cnpj} onChange={e => setEmp_cnpj(e.target.value)} /></Stack>
                              </GridItem>

                              <GridItem colSpan={12}>
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>E-mail</Text><Input borderColor={'gray'} maxLength={200} size='sm' value={emp_email} onChange={e => setEmp_email(e.target.value)} /></Stack>
                              </GridItem>
                            </Grid>
                          </Box>

                          <Box display={emp_tipo === "Física" ? 'block' : 'none'}>
                            <Grid
                              h='150px'
                              templateRows='repeat(5, 1fr)'
                              templateColumns='repeat(12, 1fr)'
                              gap={4}
                            >
                              <GridItem colSpan={5} >
                                <Stack spacing={3} direction='row'><Text>Nome</Text><Input borderColor={'gray'} maxLength={250} placeholder='Nome Completo' size='sm' value={emp_nome} onChange={e => setEmp_nome(e.target.value)} /></Stack>
                              </GridItem>
                              <GridItem colSpan={3}>
                                <Stack spacing={3} direction='row'><Text>CPF</Text><Input borderColor={'gray'} maxLength={14} size='sm' value={emp_cpf} onChange={e => setEmp_cpf(e.target.value)} /></Stack>
                              </GridItem>
                              <GridItem colSpan={4}>
                                <Stack spacing={3} direction='row'><Text>Nacionalidade</Text><Input borderColor={'gray'} maxLength={50} size='sm' value={emp_nacionalidade} onChange={e => setEmp_nacionalidade(e.target.value)} /></Stack>
                              </GridItem>

                              <GridItem colSpan={4}>
                                <Stack spacing={3} direction='row'><Text whiteSpace='nowrap'>Estado Civil</Text>
                                  <Select borderColor={'gray'} size='sm' value={emp_estadocivil} onChange={e => setEmp_estadocivil(e.target.value)}>
                                    <option></option>
                                    <option>Solteiro(a)</option>
                                    <option>Solteiro(a) em União Estável</option>
                                    <option>Casado(a)</option>
                                    <option>Divorciado(a)</option>
                                    <option>Divorciado(a) em União Estável</option>
                                    <option>Separado(a)</option>
                                    <option>Separado(a) em União Estável</option>
                                    <option>Solteiro(a) com Dissolução</option>
                                    <option>Viúvo(a)</option>
                                    <option>Viúvo(a) em União Estável</option>
                                  </Select>
                                </Stack>
                              </GridItem>
                              <GridItem colSpan={4}>
                                <Stack spacing={3} direction='row'><Text>Profissão</Text><Input borderColor={'gray'} maxLength={50} size='sm' value={emp_profissao} onChange={e => setEmp_profissao(e.target.value)} /></Stack>
                              </GridItem>
                              <GridItem colSpan={4}>
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Registro Profissional</Text><Input borderColor={'gray'} maxLength={20} placeholder='Ex. OAB 000.00-00' size='sm' value={emp_registro} onChange={e => setEmp_registro(e.target.value)} /></Stack>
                              </GridItem>

                              <GridItem colSpan={12}>
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>E-mail</Text><Input borderColor={'gray'} maxLength={200} size='sm' value={emp_email} onChange={e => setEmp_email(e.target.value)} /></Stack>
                              </GridItem>
                            </Grid>
                          </Box>

                          <Grid
                            h={'100px'}
                            templateRows='repeat(4, 1fr)'
                            templateColumns='repeat(12, 1fr)'
                            gap={4}
                          >
                            <GridItem colSpan={5}>
                              <Stack spacing={3} direction='row'><Text>Logradouro</Text><Input borderColor={'gray'} maxLength={200} placeholder='Rua, Avenida, Beco, Rodovia...' size='sm' value={emp_logradouro} onChange={e => setEmp_logradouro(e.target.value)} /></Stack>
                            </GridItem>
                            <GridItem colSpan={2}>
                              <Stack spacing={3} direction='row'><Text>Número</Text><Input borderColor={'gray'} maxLength={10} size='sm' value={emp_numero} onChange={e => setEmp_numero(e.target.value)} /></Stack>
                            </GridItem>
                            <GridItem colSpan={5}>
                              <Stack spacing={3} direction='row'><Text>Complemento</Text><Input borderColor={'gray'} maxLength={100} size='sm' value={emp_complemento} onChange={e => setEmp_complemento(e.target.value)} /></Stack>
                            </GridItem>
                            <GridItem colSpan={4}>
                              <Stack spacing={3} direction='row'><Text>Bairro</Text><Input borderColor={'gray'} maxLength={100} size='sm' value={emp_bairro} onChange={e => setEmp_bairro(e.target.value)} /></Stack>
                            </GridItem>
                            <GridItem colSpan={4}>
                              <Stack spacing={3} direction='row'><Text>Município</Text><Input borderColor={'gray'} maxLength={100} size='sm' value={emp_municipio} onChange={e => setEmp_municipio(e.target.value)} /></Stack>
                            </GridItem>
                            <GridItem colSpan={2}>
                              <Stack spacing={3} direction='row'><Text>UF</Text><Input borderColor={'gray'} maxLength={2} size='sm' value={emp_uf} onChange={e => setEmp_uf(e.target.value)} /></Stack>
                            </GridItem>
                            <GridItem colSpan={2}>
                              <Stack spacing={3} direction='row'><Text>CEP</Text><Input borderColor={'gray'} maxLength={10} size='sm' value={emp_cep} onChange={e => setEmp_cep(e.target.value)} /></Stack>
                            </GridItem>
                          </Grid>
                        </Box>
                        <Divider borderColor={'gray.400'} orientation='horizontal' mb={4} />
                        <Flex mr='20px'>
                          <ButtonGroup variant='outline' spacing='3' display={emp_tipo === "Jurídica" ? 'block' : 'none'}>
                            <Button onClick={e => lerDadosEmpresa('ISE')}>Integral Soluções em Engenharia</Button>
                            <Button onClick={e => lerDadosEmpresa('MCL')}>Minha Casa Legal</Button>
                          </ButtonGroup>
                          <Spacer />
                          <Button colorScheme="teal" variant='outline' onClick={e => salvarContratada()}>Salvar Contratada<SaveIcon ml={2} /></Button>
                        </Flex>
                      </Box>
                    </Flex>
                  </Container>
                </VStack>
              </TabPanel>

              <TabPanel className="contrato">
                <VStack>
                  <Container maxW='1300px'>
                    <Flex>
                      <Box w='20%' textAlign={'center'}>
                        <Stack dir="column">
                          <Heading as='h4' size='md' textAlign={'center'}>
                            Tipo de Contrato
                          </Heading>
                          <Button colorScheme={con_tipo === "Valor Único" ? 'teal' : 'gray'} w={"100%"} rounded={0} h={'70px'} mt={5} onClick={e => setCon_tipo("Valor Único")}>Valor Único</Button>
                          <Button colorScheme={con_tipo === "Valor Diferenciado" ? 'teal' : 'gray'} w={"100%"} rounded={0} h={'70px'} mt={1} onClick={e => setCon_tipo("Valor Diferenciado")}>Valor Diferenciado</Button>
                        </Stack>
                      </Box>
                      <Box flex='1' pl={10}>
                        <Heading as='h4' size='md' textAlign={'center'}>
                          Dados do Contrato
                        </Heading>
                        <Box p={5} pt={0}>
                          <Box display={con_tipo === "Valor Único" ? 'block' : 'none'}>
                            <Grid
                              h='180px'
                              templateRows='repeat(4, 1fr)'
                              templateColumns='repeat(12, 1fr)'
                              gap={4}
                              mb='10px'
                              mt='40px'
                            >
                              <GridItem colSpan={4} >
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Valor Global (R$)</Text><Input borderColor={'gray'} maxLength={10} size='sm' value={con_valor_global} onChange={e => { setCon_valor_global(e.target.value); valorParcelaContrato(e.target.value, con_qtd_parcelas); }} /></Stack>
                              </GridItem>
                              <GridItem colSpan={4} >
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Número de Parcelas</Text><Input borderColor={'gray'} maxLength={5} size='sm' value={con_qtd_parcelas} onChange={e => { setCon_qtd_parcelas(e.target.value); valorParcelaContrato(con_valor_global, e.target.value); }} /></Stack>
                              </GridItem>
                              <GridItem colSpan={4} >
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Valor da Parcela (R$)</Text><Input borderColor={'gray'} size='sm' value={con_valor_parcela} onChange={e => setCon_valor_parcela(e.target.value)} readOnly /></Stack>
                              </GridItem>

                              <GridItem colSpan={4} >
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Dia do Vencimento</Text><Input borderColor={'gray'} maxLength={3} size='sm' value={con_dia_vencimento} onChange={e => setCon_dia_vencimento(e.target.value)} /></Stack>
                              </GridItem>
                              <GridItem colSpan={8} >
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Comarca do Foro</Text><Input borderColor={'gray'} maxLength={100} size='sm' value={con_comarca} onChange={e => setCon_comarca(e.target.value)} /></Stack>
                              </GridItem>

                              <GridItem colSpan={12} >
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Início do Pagamento</Text><Input borderColor={'gray'} maxLength={250} size='sm' placeholder="Exemplo: no mês seguinte após o protocolo dos documentos do requerente na Prefeitura" value={con_inicio_pagamento} onChange={e => setCon_inicio_pagamento(e.target.value)} /></Stack>
                              </GridItem>

                              <GridItem colSpan={6} >
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Multa por Alteração de Requerente (%)</Text><Input borderColor={'gray'} maxLength={5} size='sm' value={con_multa_alter_porcent} onChange={e => setCon_multa_alter_porcent(e.target.value)} /></Stack>
                              </GridItem>
                              <GridItem colSpan={6} >
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Multa por Cancelamento Injustificado (%)</Text><Input borderColor={'gray'} maxLength={5} size='sm' value={con_multa_cancel_porcent} onChange={e => setCon_multa_cancel_porcent(e.target.value)} /></Stack>
                              </GridItem>

                            </Grid>
                          </Box>

                          <Box display={con_tipo === "Valor Diferenciado" ? 'block' : 'none'}>
                            <Heading as='h5' size='sm'>Reurb-S</Heading>
                            <Divider borderColor={'gray.400'} orientation='horizontal' mt={1} mb={4} />
                            <Grid
                              h='40px'
                              templateRows='repeat(1, 1fr)'
                              templateColumns='repeat(12, 1fr)'
                              gap={4}
                            >
                              <GridItem colSpan={4} >
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Valor Global S (R$)</Text><Input borderColor={'gray'} maxLength={10} size='sm' value={con_valor_global_s} onChange={e => { setCon_valor_global_s(e.target.value); valorParcelaContratoS(e.target.value, con_qtd_parcelas_s); }} /></Stack>
                              </GridItem>
                              <GridItem colSpan={4} >
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Número de Parcelas S</Text><Input borderColor={'gray'} maxLength={5} size='sm' value={con_qtd_parcelas_s} onChange={e => { setCon_qtd_parcelas_s(e.target.value); valorParcelaContratoS(con_valor_global_s, e.target.value); }} /></Stack>
                              </GridItem>
                              <GridItem colSpan={4} >
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Valor da Parcela S (R$)</Text><Input borderColor={'gray'} size='sm' value={con_valor_parcela_s} onChange={e => setCon_valor_parcela_s(e.target.value)} readOnly /></Stack>
                              </GridItem>
                            </Grid>
                            <Heading as='h5' size='sm' mt={4}>Reurb-E</Heading>
                            <Divider borderColor={'gray.400'} orientation='horizontal' mt={1} mb={4} />
                            <Grid
                              h='40px'
                              templateRows='repeat(1, 1fr)'
                              templateColumns='repeat(12, 1fr)'
                              gap={4}
                            >
                              <GridItem colSpan={4} >
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Valor Global E (R$)</Text><Input borderColor={'gray'} maxLength={10} size='sm' value={con_valor_global_e} onChange={e => { setCon_valor_global_e(e.target.value); valorParcelaContratoE(e.target.value, con_qtd_parcelas_e); }} /></Stack>
                              </GridItem>
                              <GridItem colSpan={4} >
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Número de Parcelas E</Text><Input borderColor={'gray'} maxLength={5} size='sm' value={con_qtd_parcelas_e} onChange={e => { setCon_qtd_parcelas_e(e.target.value); valorParcelaContratoE(con_valor_global_e, e.target.value); }} /></Stack>
                              </GridItem>
                              <GridItem colSpan={4} >
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Valor da Parcela E (R$)</Text><Input borderColor={'gray'} size='sm' value={con_valor_parcela_e} onChange={e => setCon_valor_parcela_e(e.target.value)} readOnly /></Stack>
                              </GridItem>
                            </Grid>

                            <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={6} />
                            <Grid
                              h='140px'
                              templateRows='repeat(3, 1fr)'
                              templateColumns='repeat(12, 1fr)'
                              gap={4}
                            >
                              <GridItem colSpan={4} >
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Dia do Vencimento</Text><Input borderColor={'gray'} maxLength={3} size='sm' value={con_dia_vencimento} onChange={e => setCon_dia_vencimento(e.target.value)} /></Stack>
                              </GridItem>
                              <GridItem colSpan={8} >
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Comarca do Foro</Text><Input borderColor={'gray'} maxLength={100} size='sm' value={con_comarca} onChange={e => setCon_comarca(e.target.value)} /></Stack>
                              </GridItem>

                              <GridItem colSpan={12} >
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Início do Pagamento</Text><Input borderColor={'gray'} maxLength={250} size='sm' placeholder="Exemplo: no mês seguinte após o protocolo dos documentos do requerente na Prefeitura" value={con_inicio_pagamento} onChange={e => setCon_inicio_pagamento(e.target.value)} /></Stack>
                              </GridItem>

                              <GridItem colSpan={6} >
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Multa por Alteração de Requerente (%)</Text><Input borderColor={'gray'} maxLength={5} size='sm' value={con_multa_alter_porcent} onChange={e => setCon_multa_alter_porcent(e.target.value)} /></Stack>
                              </GridItem>
                              <GridItem colSpan={6} >
                                <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Multa por Cancelamento Injustificado (%)</Text><Input borderColor={'gray'} maxLength={5} size='sm' value={con_multa_cancel_porcent} onChange={e => setCon_multa_cancel_porcent(e.target.value)} /></Stack>
                              </GridItem>

                            </Grid>
                          </Box>
                          <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={6} />
                          <Grid
                            h='30px'
                            templateRows='repeat(3, 1fr)'
                            templateColumns='repeat(12, 1fr)'
                            gap={4}
                          >
                            <GridItem colSpan={2} >
                              <Checkbox borderColor={'gray'} size='md' colorScheme='teal' value={con_adicional} onChange={e => setCon_adicional(e.target.checked ? 1 : 0)} isChecked={con_adicional === 1}>Valor Adicional</Checkbox>
                            </GridItem>
                            <GridItem colSpan={3} >
                              <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>APP (R$)</Text><Input borderColor={'gray'} size='sm' value={con_valor_app} onChange={e => setCon_valor_app(e.target.value)} isDisabled={con_adicional === 1 ? false : true} /></Stack>
                            </GridItem>
                            <GridItem colSpan={3} >
                              <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>Risco (R$)</Text><Input borderColor={'gray'} size='sm' value={con_valor_risco} onChange={e => setCon_valor_risco(e.target.value)} isDisabled={con_adicional === 1 ? false : true} /></Stack>
                            </GridItem>
                            <GridItem colSpan={4} >
                              <Stack spacing={3} direction='row'><Text whiteSpace={'nowrap'}>APP e Risco (R$)</Text><Input borderColor={'gray'} size='sm' value={con_valor_apperisco} onChange={e => setCon_valor_apperisco(e.target.value)} isDisabled={con_adicional === 1 ? false : true} /></Stack>
                            </GridItem>

                          </Grid>
                          <Divider borderColor={'gray.400'} orientation='horizontal' mt={8} mb={4} />
                          <Flex m='20px'>
                            <Spacer />
                            <Button colorScheme="teal" variant='outline' onClick={e => salvarContrato()}>Salvar Contrato<SaveIcon ml={2} /></Button>
                          </Flex>
                        </Box>
                      </Box>
                    </Flex>
                  </Container>
                </VStack>
              </TabPanel>

              <TabPanel className="timbrado">
                <VStack>
                  <Container maxW='1100px'>
                    <SimpleGrid columns={2} spacing={10}>
                      <Box>
                        <Stack direction='column' spacing={3} textAlign={'center'}>
                          <Heading as='h4' size='md' textAlign={'center'} mb={5}>
                            Definir Modelo de Timbrado da Remessa
                          </Heading>
                          <Center>
                            <Select borderColor={'gray'} size='md' w='500px' mb={5} value={tim_selecionado} onChange={e => { handleTimbradoSelecionado(parseInt(e.target.value)) }}>
                              <option key={0} value={0}>{''}</option>
                              {lista_timbrados.map((item, i) => {
                                return (<option key={i + 1} value={item.tim_id}>{item.tim_nome}</option>);
                              })}
                            </Select>
                          </Center>
                          <Heading as='h4' size='sm' textAlign={'left'} color={'gray'}>
                            DESCRIÇÃO
                          </Heading>
                          <Divider borderColor={'gray.400'} orientation='horizontal' mt={4} mb={4} />
                          <Text textAlign={'left'}>
                            {tim_descricao}
                          </Text>
                        </Stack>
                      </Box>
                      <Box >
                        <Heading as='h4' size='md' textAlign={'center'}>
                          Visualização Prévia
                        </Heading>
                        <Center>
                          <AspectRatio w='50%' ratio={210 / 297} boxShadow='dark-lg' mt={5} display={tim_imagem !== '' ? 'block' : 'none'}>
                            <Image src={tim_imagem} alt='Timbrado' />
                          </AspectRatio>
                          <Text textAlign={'center'} mt={5} display={tim_imagem === '' ? 'block' : 'none'}>Sem Imagem</Text>
                        </Center>
                      </Box>
                    </SimpleGrid>
                  </Container>
                </VStack>
              </TabPanel>

            </TabPanels>
          </Box>
        </Tabs>
      </Container>
    </div>
  );
}

export default ConfiguracoesPage;